<template>
  <div class="flex flex-col w-full">

    <div class="flex flex-wrap justify-center items-center min-h-screen cool-gradient text-white">
      <div class="w-full lg:w-6/12 flex flex-col justify-center items-center">
        <h1>
          <span class="text-6xl font-bold">Invoice Labs</span><br/>
          <span class="text-2xl">Add chemistry to your finances</span>
        </h1>
      </div>
      <div class="w-full lg:w-6/12 flex flex-col">
        <div class="">
          <img src="section_1.svg" alt="">
        </div>
        <h2 class="text-2xl text-center mt-4">
          Make professional invoices for free.<br/>
          An excellent tool for professional freelancers and businesses.
        </h2>

      </div>
    </div>

    <div class="flex flex-col bg-il-dark items-center min-h-screen text-white">
      <div class="text-2xl uppercase font-bold">
        Pricing
      </div>

      <div class="mt-4 text-xl">
        We offer our Invoice Generator for free, FOREVER!
      </div>
      <div class="text-xl mt-2">
        Give it a try by going to our Quick Invoice page
      </div>
      <div
        class="bg-il-purple mt-4 rounded-full p-4 uppercase quick-invoice-gradient cursor-pointer font-bold"
        @click="navigateToMX('quick-invoice')"
      >
        Take me to Quick Invoice
      </div>

      <div class="mt-12 text-2xl text-center">
        If you need more functionality, we have a couple of offers for you
      </div>

      <div class="flex flex-wrap text-black">
        <div class="p-4 w-full lg:w-96">
          <div class="rounded-2xl bg-white p-4 flex flex-col">
            <div class="font-bold text-2xl">
              Free Plan
            </div>
            <div class="my-4 flex items-center">
              <span class="font-bold text-4xl">
                £0
              </span>
              <span class="ml-2 text-lg text-gray-500">
                / month
              </span>
            </div>
            <div class="uppercase font-bold mt-4">
              What's included
            </div>
            <div class="flex">
              <ul>
                <li class="mt-2">
                  <span class="text-il-teal mx-2">
                    <i class="fas fa-check"></i>
                  </span>
                  Manage 1 company
                </li>
                <li class="mt-2">
                  <span class="text-il-teal mx-2">
                    <i class="fas fa-check"></i>
                  </span>
                  Manage 3 clients per company
                </li>
                <li class="mt-2">
                  <span class="text-il-teal mx-2">
                    <i class="fas fa-check"></i>
                  </span>
                  Any Invoice accent colors
                </li>
                <li class="mt-8">

                </li>
              </ul>
            </div>

            <div class="mt-4 text-center underline">
              You just need to register
            </div>

            <div
              class="bg-il-purple mt-4 rounded-full p-4 uppercase quick-invoice-gradient cursor-pointer font-bold text-white text-center"
              @click="navigateToMX('register')"
            >
              Register Now
            </div>
          </div>
        </div>

        <div class="p-4 w-full lg:w-96">
          <div class="rounded-2xl bg-white p-4 flex flex-col">
            <div class="font-bold text-2xl">
              Premium Plan
            </div>
            <div class="my-4 flex items-center">
              <span class="font-bold text-4xl">
                £8
              </span>
              <span class="ml-2 text-lg text-gray-500">
                / month
              </span>
            </div>
            <div class="uppercase font-bold mt-4">
              What's included
            </div>
            <div class="flex">
              <ul>
                <li class="mt-2">
                  <span class="text-il-teal mx-2">
                    <i class="fas fa-check"></i>
                  </span>
                  Manage 3 companies
                </li>
                <li class="mt-2">
                  <span class="text-il-teal mx-2">
                    <i class="fas fa-check"></i>
                  </span>
                  Manage 10 clients per company
                </li>
                <li class="mt-2">
                  <span class="text-il-teal mx-2">
                    <i class="fas fa-check"></i>
                  </span>
                  Any Invoice accent colors
                </li>
                <li class="mt-2">
                  <span class="text-il-teal mx-2">
                    <i class="fas fa-check"></i>
                  </span>
                  Send Invoice to Client's Email
                </li>
              </ul>
            </div>

            <div class="mt-4 text-center underline">
              Starts with a 30-day free trial. No Card.
            </div>

            <div
              class="bg-il-purple mt-4 rounded-full p-4 uppercase quick-invoice-gradient cursor-pointer font-bold text-white text-center"
              @click="navigateToMX('register')"
            >
              Register Now
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  data() {
    return {

    }
  },
  mounted() {
  },
  components: {
  },
  methods: {

  },
  computed: {
  }
}
</script>

<style scoped>
  .cool-gradient {
    background-image: linear-gradient(to bottom, #8458b3, #504b94, #253b6f, #062949, #001524);
  }
  .cool-gradient-2 {
    background-image: linear-gradient(to bottom, #001524, #3d4654, #7b7e89, #bcbcc2, #ffffff);
  }
  .quick-invoice-gradient {
    background-image: linear-gradient(to right bottom, #009382, #008ea3, #0084bf, #2873c7, #8458b3);
  }
</style>